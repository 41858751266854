
    var riot = require('riot')
    
riot.tag2('product', '<div each="{product in products}" if="{products.length &gt; 0}"> <h3>{product.reference}</h3> <p if="{product.description}">{product.description}</p> <buy productid="{product.id}" price="{product.price}" showprice="{true}" showqty="{true}" src="/duocms/api/basket" vat="{!product.vat}" currency="EUR"></buy><a if="{opts.editmode}" href="/duocms/ecommerce/product/{product.id}" target="_blank" class="btn btn-primary pull-right">Edit Product</a> <hr> </div>', 'product h3 { padding-bottom: 0; } product p { font-size: 13px; }', '', function(opts) {
var resource;

resource = require('riot-kit/utils/apiResource.coffee');

this.on('mount', function() {
  this.productsApi = new resource('/duocms/api/products');
  return this.productsApi.get({
    page_id: opts.page_id,
    page_path: opts.page_path
  }, (function(_this) {
    return function(products) {
      _this.products = products;
      return _this.update();
    };
  })(this));
});
});
    
  