
    var riot = require('riot')
    
riot.tag2('addsubpage', '<yield></yield> <div class="addpage"><a onclick="{save}" class="btn btn-primary">Add {title}<span class="hidden-xs"> to section </span>&nbsp;<i class="fa fa-plus"></i></a> <div if="{save_error}" class="alert alert-danger">{save_error}</div> <div if="{success_message}" class="alert alert-success">{success_message}</div> </div>', '.addpage { margin-top: 2px; }', '', function(opts) {
var pagesApi, resource, usersApi;

resource = require('riot-kit/utils/apiResource.coffee');

pagesApi = new resource('/duocms/api/pages/', true);

usersApi = new resource('/duocms/api/users/', true);

this.title = "page";

this.on('mount', function() {
  if (this.opts.title) {
    this.title = this.opts.title;
  }
  return this.update();
});

this.save = (function(_this) {
  return function() {
    var now;
    _this.save_error = null;
    if (_this.opts.parent_id && _this.opts.parent_path) {
      _this.template = _this.opts.template || "default";
      now = new Date().toISOString().slice(0, 19).replace('T', ' ');
      _this.pagedata = {
        title: "New Page",
        template: _this.template,
        path: _this.opts.parent_path + "new_page/",
        parent_id: _this.opts.parent_id,
        button: "1",
        pagedate: now,
        created_at: now,
        updated_at: now,
        html: '<h1>New Page</h1><p>A default new page</p>'
      };
      return pagesApi.post(_this.pagedata, function(res) {
        var ref;
        if (res.id) {
          _this.success_message = "Page added successfully";
          if (res.path) {
            window.location.href = res.path;
          } else {
            window.location.reload();
          }
        } else if ((ref = res.body) != null ? ref.message : void 0) {
          _this.save_error = res.body.message;
          setTimeout((function() {
            return _this.save_error = null;
          }), 3000);
        } else {
          _this.save_error = "Unable to add page to section, please try again.";
          setTimeout((function() {
            return _this.save_error = null;
          }), 3000);
        }
        return _this.update();
      });
    }
  };
})(this);
});
    
  