
    var riot = require('riot')
    
riot.tag2('mydownloads', '<div if="{!downloads}" class="alert alert-info">Getting Downloads List</div> <div if="{downloads.length == 0}" class="alert alert-info">You have no purchased downloads</div> <div if="{downloads.length}"> <table class="table table-striped"> <thead> <tr> <th>Title </th> <th>Purchase Date </th> <th> </th> </tr> </thead> <tbody> <tr each="{downloads}"> <td>{title}</td> <td> <dateformat date="{new Date(date)}" format="HH:mm dd/MM/yyyy"></dateformat> </td> <td> <button onclick="{download}" class="btn btn-primary">Open</button> </td> </tr> </tbody> </table> </div> <modal name="docfail" bssize="md" onclose="{close}" class="docfail"> <div class="alert alert-info">Sorry, the selected item <strong if="{parent.errordoc}">{parent.errordoc}</strong> is not currently available in electronic form as part of your purchase.<br><br>If you feel this is an error, please <a href="/contact_us/">contact us</a>.</div> </modal>', '', '', function(opts) {
var xhr;

xhr = require("../libs/xhr.coffee");

this.on("mount", function() {
  this.downloads = null;
  return xhr("GET", "/duocms/api/mydownloads", null, (function(_this) {
    return function(res) {
      _this.downloads = res.body.data || [];
      return _this.update();
    };
  })(this));
});

this.download = (function(_this) {
  return function(e) {
    var item;
    item = e.item;
    if (item.document_url) {
      return window.open("/duocms/api/download?document_url=" + item.document_url + "&token=" + item.token);
    } else {
      _this.errordoc = item.title || "";
      return _this.tags.docfail.show();
    }
  };
})(this);
});
    
  