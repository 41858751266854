
    var riot = require('riot')
    
riot.tag2('pagedate', '<yield></yield> <formdate name="picker" label="page date" dateformat="dd/MM/yyyy" value="{pagedate}" onupdate="{setPageDate}"></formdate> <input type="hidden" name="page.pagedate" value="{dbdate}" class="duoEdittext">', '', '', function(opts) {
this.on('mount', function() {
  this.pagedate = new Date(this.opts.date) || new Date();
  this.dbdate = this.pagedate.toISOString().slice(0, 19).replace('T', ' ');
  return this.update();
});

this.setPageDate = (function(_this) {
  return function() {
    return setTimeout(function() {
      var date, newdate;
      console.log(_this.picker.querySelector('input').value);
      date = _this.picker.querySelector('input').value.split("/");
      newdate = new Date();
      newdate.setDate(date[0]);
      newdate.setMonth(date[1] - 1);
      newdate.setYear(date[2]);
      newdate.setHours(_this.pagedate.getHours());
      newdate.setMinutes(_this.pagedate.getMinutes());
      newdate.setSeconds(_this.pagedate.getSeconds());
      _this.dbdate = newdate.toISOString().slice(0, 19).replace('T', ' ');
      return _this.update();
    }, 500);
  };
})(this);
});
    
  