$ = jQuery = window.jQuery = window.$ = require "jquery"
require 'owl.carousel'

window.riot = riot = require 'riot'
require 'riot-kit/lib/alert'
require 'riot-kit/lib/slideshow'
require 'riot-kit/lib/navigation'
require 'riot-kit/lib/dropdown'
require 'riot-kit/lib/tags'
require 'riot-kit/lib/formdate'
require 'riot-kit/lib/datepicker'
require 'riot-kit/lib/ecommerce'
# require 'riot-kit/lib/dateformat'

require 'riot-kit/lib/gallery'

require './tags/signumber.tag'
require './tags/addsubpage.tag'
require './tags/pagedate.tag'
require './tags/pubs-form.tag'

# ecommerce additions - July 2018
require 'riot-kit/lib/modal'
require 'riot-kit/lib/auth'
require 'riot-kit/lib/ecommerce'
require './tags/buy.tag'
# require './tags/checkout.tag'
require './tags/auth/signin.tag'
require './tags/auth/signup.tag'
require './tags/uploadimage.tag'
require './tags/change-password.tag'
require './tags/personal-details.tag'
require './tags/signin-modal.tag'
require './tags/mydownloads.tag'
require 'riot-kit/lib/dateformat'
require 'riot-kit/lib/toaster'
require './tags/product.tag'
require './tags/pricetag.tag'
require './tags/mypassword.tag'
require './tags/mydetails.tag'

require './tags/navigation-etmm.tag'

require './libs/slideshow.coffee'

require './tags/twitter.tag'

# require './tags/checkout.tag'
# require './tags/basket.tag'


riot.mount('*')
document.addEventListener 'duocms:updated', -> riot.mount('slideshow')

editmode = document.querySelector("body.editmode")
ctas = document.querySelectorAll('.cta')
if ctas.length > 0 && !editmode
  [].forEach.call ctas, (cta)->
    link = cta.querySelector('a')
    if link
      h = link.getAttribute('href')
      cta.addEventListener 'click',(e)->
        window.location.href = h

qls = document.querySelectorAll('.indexql')
if qls.length > 0 && !editmode
  [].forEach.call qls, (ql)->
    link = ql.querySelector('a')
    # console.log link
    if link
      h = link.getAttribute('href')
      # console.log h
      ql.addEventListener 'click',(e)->
        window.location.href = h

nav = document.querySelector('.navicon')
nav.addEventListener 'click', (e)->
  togglenav()

togglenav = ->
  if nav.className.indexOf('collapsed')>-1 # || nav2.className.indexOf('collapsed')>-1
    nav.className = nav.className.replace(/ ?toggle-on ?/,'')
  else
    nav.className = nav.className.replace(/ ?toggle-on ?/,'')+' toggle-on'

ipp = document.querySelectorAll('.input-productprice')
proderror = document.querySelector('#producterror')
if ipp && ipp.length > 0
  [].forEach.call ipp, (pp)=>
    pp.addEventListener 'keyup',(e)=>
      pp.classList.remove('error')
      proderror.classList.add('hidden')
      console.log pp.classList
      if !pp.value
        pp.value = "0"
        if pp.name == "price"
          pp.classList.add('error')
          proderror.classList.remove('hidden')


# mobile nav stuff
mmutationObserver = window.MutationObserver || window.WebKitMutationObserver || window.MozMutationObserver
mo = new mmutationObserver (mutations)->
  m = 0
  # mutations.forEach (mutation)->
  while m < mutations.length
    mutation = mutations[m]
    if mutation.type == "attributes" && mutation.target.className.match(/navshowing/)
      [].slice.call(document.querySelectorAll(".navbar-nav li")).forEach (subnav)->
        subnav.addEventListener 'click',(e)->
          if subnav.getAttribute('riot-tag') == "dropdown" && subnav.className.match(/open/)
            e.preventDefault()
            subnav.querySelector('a,i').addEventListener 'click',(e)->
              window.location.href = subnav.querySelector('a').href
            subsecs = subnav.querySelectorAll('.dropdown-menu a')
            if subsecs.length > 0
              [].slice.call(subsecs).map (sec)=>
                sec.addEventListener 'click',(e)->
                  window.location.href = sec.href
    m++

mo.observe(document.querySelector('body'),{attributes:true})


window.carousel = =>
  $(document).ready ()=>
    $('.twitter-carousel.owl-carousel').owlCarousel(
      items:1
      pagination:true
      nav:false
      margin: 0
      autoplay:true
      autoplayHoverPause:true
      loop:true
      lazyLoad:true
      responsive:{
        0 : {items:1}
        480:{items:1}
        768:{items:1}
        992 : {items:1}
      }
    ).addClass('show')