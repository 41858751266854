
    var riot = require('riot')
    
riot.tag2('pubs-form', '<div if="{showform}"> <form method="POST" action="/duocms/api/formsend" class="form-horizontal"> <input type="hidden" name="_csrf" value="{csrf_token}"> <input type="text" name="duoreform{duoreform}" value="" style="display: none;"> <imput type="hidden" name="pagetitle" value="{opts.pagetitle}"></imput> <legend>Request this publication</legend> <p>Please complete the form below to order this publication</p> <div class="form-group"> <label for="Name" class="col-sm-2 control-label">First name</label> <div class="col-sm-10"> <input name="Name" placeholder="Name" type="text" value="" required class="form-control"> </div> </div> <div class="form-group"> <label for="Email" class="col-sm-2 control-label">Email</label> <div class="col-sm-10"> <input id="Email" name="email" placeholder="Email" type="text" value="" required class="form-control"> </div> </div> <div class="form-group"> <label for="Company" class="col-sm-2 control-label">Company</label> <div class="col-sm-10"> <textarea id="Address" name="address" placeholder="Address" value="" rows="3" required class="form-control"></textarea> </div> </div> <div class="form-group"> <label for="text_54110" class="col-sm-2 control-label">Are you a member?</label> <div class="col-sm-10"> <div class="radio-inline"> <label> <input type="radio" value="yes" name="member">Yes </label> </div> <div class="radio-inline"> <label> <input type="radio" value="no" name="member">No </label> </div> </div> </div> <div class="form-group"> <label for="Comments" class="col-sm-2 control-label">Comments</label> <div class="col-sm-10"> <textarea id="Comments" name="Comments" placeholder="Comments" value="" rows="4" required class="form-control"></textarea> </div> </div> <button type="submit" class="btn btn-primary pull-right">Request Publication</button> </form> </div>', '', '', function(opts) {
this.duoreform = Math.round((4872987492 + new Date().getTime()) / 1000);

this.csrf_token = window.csrf;

this.on('mount', function() {
  if (!window.location.search.match(/editmode/)) {
    this.showform = true;
  }
  return this.update();
});
});
    
  