
    var riot = require('riot')
    
riot.tag2('signin-modal', '<yield></yield> <modal name="signinmodal" onclose="{close}" title="Sign In" savetext="Sign In" onsave="{handlesignin}" esckey="{true}"> <signin onsignin="{parent.signedin}" hidebutton="{true}" hidesignup="{true}" hideforgotpassword="{parent.opts.hideforgotpassword}" forgot-password-custom-message="{parent.opts.forgotPasswordCustomMessage}" redirect="{parent.opts.onLoginRedirect}"></signin><a href="/about/ercoftac_membership/" class="btn btn-primary member-btn">Become a member</a> </modal> <modal name="signoutmodal" onclose="{close}" title="Account" savetext="Logout" onsave="{logout}" esckey="{true}"> <div class="row"> <div class="col-xs-12"> <h3> <a href="/members/">Member\'s Area -></a></h3> </div> <div class="col-xs-6"> <ul class="accountlist"> <li><a href="/members/bulletin/">ERCOFTAC Bulletin</a></li> <li><a href="/members/order_history/">Order History</a></li> <li><a href="/members/update/">Update My Details</a></li> </ul> </div> <div class="col-xs-6"> <ul class="accountlist"> <li><a href="/members/updatepassword/">Update My Password</a></li> <li><a href="/members/my_downloads/">My Downloads</a></li> <li><a href="/members/customer_support/">Customer Support</a></li> </ul> </div> </div> </modal>', 'signin-modal { cursor: pointer; } .member-btn { float: left; margin-top: 31px; }', 'onclick="{show}"', function(opts) {
var loginApi, logoutApi, resource;

resource = require('riot-kit/utils/apiResource.coffee');

loginApi = new resource(opts.src || '/duocms/api/login', true);

logoutApi = new resource(opts.src || '/duocms/api/logout', true);

this.on('mount', function() {
  this.root.trigger = this.trigger;
  this.signinmodal = this.tags.signinmodal;
  this.signoutmodal = this.tags.signoutmodal;
  this.signin = this.signinmodal.tags.signin;
  return this.update();
});

this.on('show', (function(_this) {
  return function() {
    return _this.show();
  };
})(this));

this.show = (function(_this) {
  return function() {
    document.addEventListener('keydown', _this.keydown);
    return loginApi.get(function(data, err) {
      if (err == null) {
        return _this.signoutmodal.show();
      } else {
        return _this.signinmodal.show();
      }
    });
  };
})(this);

this.keydown = (function(_this) {
  return function(e) {
    if (e.which === 13) {
      return _this.handlesignin();
    }
  };
})(this);

this.logout = (function(_this) {
  return function() {
    console.log('logout');
    return logoutApi.get(function(data, err) {
      if (err != null) {
        console.log('error', err);
      }
      if (err == null) {
        console.log('logged out', data);
      }
      if (opts.onLogoutRedirect != null) {
        window.location.href = opts.onLogoutRedirect;
      }
      return _this.signoutmodal.close();
    });
  };
})(this);

this.close = (function(_this) {
  return function() {
    document.removeEventListener('keydown', _this.keydown);
    return _this.signin.trigger('reset');
  };
})(this);

this.signedin = (function(_this) {
  return function(user) {
    _this.signinmodal.close();
    if ((opts.onsignin != null) && typeof opts.onsignin === "function") {
      return opts.onsignin(user);
    } else {
      return window.location.reload();
    }
  };
})(this);

this.handlesignin = (function(_this) {
  return function() {
    return _this.signin.trigger('signin');
  };
})(this);
});
    
  