
    var riot = require('riot')
    require('riot-kit/lib/ecommerce/currency');require('riot-kit/lib/i18n');
riot.tag2('buy', '<form class="form-horizontal"> <div class="form-group"> <label if="{opts.showprice}" class="control-label col-xs-7"> <currency value="{opts.price}" code="{opts.currency}"> </currency><span if="{!opts.vat}">(ex-VAT)</span> </label> <div class="col-xs-5 {col-xs-offset-7: !opts.showprice}"> <div if="{opts.showqty}" class="input-group"> <input type="text" value="{opts.qty || 1}" name="quantity" class="form-control"><span class="input-group-btn"> <button type="button" onclick="{handleBuyClick}" class="btn {opts.btnClass || \'btn-primary\'}"> <i18n content="{opts.btntext || \'Buy\'}"></i18n> </button></span> </div> <button if="{!opts.showqty}" type="button" onclick="{handleBuyClick}" class="btn {opts.btnClass || \'btn-primary\'}"> <i18n content="{opts.btntext || \'Buy\'}"></i18n> </button> </div> </div> </form>', 'buy .form-control { height: 36px; }', '', function(opts) {
var basketResource, resource, tagSelector;

resource = require('riot-kit/utils/apiResource.coffee');

tagSelector = require('riot-kit/utils/tagSelector.coffee');

basketResource = new resource(opts.src || '/duocms/api/basket');

this.on('mount', (function(_this) {
  return function() {
    _this.basket = tagSelector('basket', 'popup-basket');
    return _this.update();
  };
})(this));

this.handleBuyClick = (function(_this) {
  return function() {
    var qty, ref;
    qty = (ref = _this.quantity) != null ? ref.value : void 0;
    return basketResource.post({
      id: opts.productid,
      qty: qty
    }, function() {
      if (_this.basket) {
        return _this.basket.trigger('updateBasket');
      }
    });
  };
})(this);
});
    
  