
    var riot = require('riot')
    
riot.tag2('change-password', '<formhorizontal width1="2" width2="10"> <p riot-tag="i18n">Please enter a new password and confirm by retyping it.</p> <forminput name="new_password" label="New Password" onupdate="{parent.handleUpdate}" type="password" required min="8"></forminput> <forminput name="new_password_confirm" label="Retype Password" onupdate="{parent.handleUpdate}" type="password" required></forminput> <div if="{parent.error}" class="alert alert-danger">{parent.errorMessage.message}</div> <div if="{parent.success}" class="alert alert-success">{parent.successMessage}</div> <div onclick="{parent.submit}" class="btn btn-primary pull-right"> <i18n>Change Password</i18n> </div> </formhorizontal>', '', '', function(opts) {
var resource, userApi;

resource = require('riot-kit/utils/apiResource.coffee');

userApi = new resource('/duocms/api/users', true);

this.handleUpdate = (function(_this) {
  return function(input) {
    return _this[input.name] = input.value;
  };
})(this);

this.submit = (function(_this) {
  return function() {
    if (_this.new_password === _this.new_password_confirm) {
      _this.error = false;
      _this.success = false;
      _this.update();
      return userApi.save({
        id: 'me',
        password: _this.new_password
      }, function(user, err) {
        if (err != null) {
          return _this.showError(err);
        } else {
          return _this.showSuccess('Your password has been successfully changed');
        }
      });
    } else {
      return _this.showError({
        message: 'Passwords do not match'
      });
    }
  };
})(this);

this.showError = (function(_this) {
  return function(errorMessage) {
    _this.errorMessage = errorMessage;
    _this.error = true;
    return _this.update();
  };
})(this);

this.showSuccess = (function(_this) {
  return function(successMessage) {
    _this.successMessage = successMessage;
    _this.success = true;
    return _this.update();
  };
})(this);
});
    
  