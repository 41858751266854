
    var riot = require('riot')
    
riot.tag2('navigation-etmm', '<div class="{darken}"><yield></yield></div>', '', '', function(opts) {
this.show = false;

this.toggle = (function(_this) {
  return function() {
    _this.show = !_this.show;
    if (_this.show) {
      document.querySelector('body').classList.add('navshowing');
    } else {
      document.querySelector('body').classList.remove('navshowing');
    }
    return _this.update();
  };
})(this);
});
    
  