
    var riot = require('riot')
    
riot.tag2('signumber', '<yield></yield> <div class="signumber"> <svg viewbox="0 0 100 100" class="sigsvg"> <circle fill="#134A83" cx="50" cy="50" r="50"></circle> <text x="50" y="50" fill="white" text-anchor="middle" alignment-baseline="central" style="font-family:\'Rajdhani\',\'Helvetica Neue\',Helvetica,Arial,sans-serif;"> <tspan x="50" y="40" style="font-size:28px;font-weight:300">SIG</tspan> <tspan x="50" y="75" style="font-size:36px;font-weight:500">33</tspan> </text> </svg> </div>', '.signumber { display: block; position: relative; width: 60%; margin: 0 20% 30px 20%; padding-bottom: 60%; vertical-align: middle; overflow: hidden; } .signumber .sigsvg { display: inline-block; position: absolute; top: 0; left: 0; }', '', function(opts) {
var pagesApi, resource;

resource = require('riot-kit/utils/apiResource.coffee');

pagesApi = new resource('/duocms/api/pages/', true);

this.on('mount', function() {
  if (this.opts.id) {
    console.log(this.opts.id);
    return pagesApi.get({
      id: this.opts.id
    }, (function(_this) {
      return function(data) {
        console.log(data);
        return _this.update();
      };
    })(this));
  }
});
});
    
  